import type {
  company,
  country,
  invoice,
  invoice_position,
} from "@prisma/client";
import type { Decimal } from "@prisma/client/runtime/library";
import axios from "axios";
import type { TagItem } from "~/components/tags/constants";
import { LANGUAGE_EN } from "~/constants/constants";
import type { invoicePDF, invoicepositionPDF } from "~/types/pdftypes";
import type {
  TradeProductWithIncludes,
  UserTradeProductOverview,
} from "~/types/TradeProductTypes";
import { printSafeDateString } from "./tools";

export function getErrorMessage(error: unknown) {
  if (typeof error === "string") return error;
  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return error.message;
  }
  console.error("Unable to get error message for error", error);
  return "Unknown Error";
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit["headers"] | null | undefined>
) {
  let combined = new Headers();
  for (let header of headers) {
    if (!header) continue;
    for (let [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function isValidNumber(stringValue: string): boolean {
  let numberValue = parseFloat(stringValue);
  // Check if the parsed value is a valid number and not NaN
  // Also, ensure that the input is not an empty string or contains only whitespaces
  return !isNaN(numberValue) && isFinite(numberValue) && /\S/.test(stringValue);
}

export function getValidNumber(stringValue: string): number | undefined {
  let numberValue = parseFloat(stringValue);
  // Check if the parsed value is a valid number and not NaN
  // Also, ensure that the input is not an empty string or contains only whitespaces
  if (!isNaN(numberValue) && isFinite(numberValue) && /\S/.test(stringValue)) {
    return numberValue;
  }
  return undefined;
}

export function convertTagItemTypeToString(
  ti: TagItem,
  languageID: string
): string {
  let elem = ti.elements.find((e) => e.language.id === languageID);
  // if correct language is not available, fallback to english if possible, else use any other language.
  if (!elem) {
    elem = ti.elements.find((e) => e.language.id === LANGUAGE_EN);
    if (!elem) {
      elem = ti.elements[0];
    }
  }
  return `${elem.key} : ${elem.value}`;
}

export async function doUIDRequest(vatRegNo: string) {
  let requestUIDUrl = String(process.env.UID_REQUEST_URL);
  //TODOISSUE #204
  const requestParams = {
    TID: "64357330p261",
    BenID: "webservice1",
    UID_tn: "ATU77627739",
    UID: vatRegNo,
    Stufe: "2",
  };
  return await axios
    .post(requestUIDUrl, requestParams, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      //    hier nix tun, wollen auch mit error einfach weitermachen, response landet in der DB Tabelle UIDCheck, vllt mal loggen aber eigt egal?       console.log(err);
    });
}

export async function createInvoicePDF(
  invoice: invoice,
  invoice_positions: invoice_position[],
  company: company,
  country: country
) {
  let requestInvoiceUrl = String(process.env.INVOICE_PDF_URL);
  let invoicPositions: invoicepositionPDF[] = invoice_positions.map((ip) => {
    return {
      description: "",
      amount: ip.amount,
      tax: ip.tax,
      taxamount: ip.taxamount,
    };
  });

  let requestParams: invoicePDF = {
    companycountry: country.Title,
    companyname: invoice.billing_address_companyname,
    companyplace: invoice.billing_address_place,
    companystreet: invoice.billing_address_street,
    companyzip: invoice.billing_address_zip,
    companyuid: company.VATRegNo ?? "-",
    invoicedate: printSafeDateString(invoice.invoice_date),
    invoicenumber: invoice.invoice_number,
    totalamount: invoice.netto_amount,
    totalamountgross: invoice.gross_amount,
    invoicepositions: invoicPositions,
  };

  let response = await axios
    .post(requestInvoiceUrl, requestParams, {
      headers: { "Content-Type": "application/json" },
      responseType: "arraybuffer",
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      /* v8 ignore next 2 */
      console.log(err);
      //    hier nix tun, wollen auch mit error einfach weitermachen, response landet in der DB Tabelle UIDCheck, vllt mal loggen aber eigt egal?       console.log(err);
    });

  return response;
}

export function CalculateAmountOfInvoicePosition(
  price_per_unit: Decimal,
  quantity: Decimal
) {
  let result: Decimal;

  result = price_per_unit.mul(quantity).mul(0.008).add(8);

  return result;
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ??
    request.headers.get("host") ??
    new URL(request.url).host;
  const protocol = request.headers.get("X-Forwarded-Proto") ?? "http";
  return `${protocol}://${host}`;
}

export function hasValue(value: any) {
  return value !== null && value !== undefined;
}

export function findRightAgreedPlace(
  tradeproduct: TradeProductWithIncludes,
  usertradeproduct: UserTradeProductOverview
) {
  //TODOSL bau die richtige Logik um die richtige CompanyAddress zurück zu bekommen.

  return tradeproduct.delivery_address;
}
